import React from 'react';
import MainHeader from '../../components/MainHeader';
import CompanyOverview from '../../components/ComapnyOverview'
import OurServices from '../../components/OurServices';
import '../home/home.css';

const Home = () => {
  return (
    <>
      <MainHeader />
      <CompanyOverview />
      <OurServices />

      {/* Notion Calendar Booking Section */}
      <section className="booking-section">
        <h2 className="booking-title">Book a Meeting with Us</h2>
        <iframe
          src="https://calendar.notion.so/meet/preciousoagi/mo2r3fvd"
          title="Notion Calendar Booking"
          style={{
            width: '100%',
            height: '700px',
            border: 'none',
            borderRadius: '8px',
            marginTop: '20px',
          }}
          aria-label="Notion calendar booking interface"
        />
        <p className="booking-note">
          Click the link above to book a convenient time for a meeting. You’ll be redirected to our Notion calendar page.
        </p>
      </section>
    </>
  );
};

export default Home;
