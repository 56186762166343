import Logo from "./../images/Agiphi (3).PNG"
import {Link} from "react-router-dom"
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { AiOutlineX } from "react-icons/ai";

const Footer = () => {
  return (
    <footer>
    <div className="footer__container">
        <article>
            <Link to="/" className="footer-logo">
            <img src={Logo} alt="Footer logo" />
            </Link>
            <p>AgiLabs platform is designed to meet the dynamic needs of AI and ML model training. With advanced tools for multimodal data annotation and customizable solutions, we empower you to enhance model performance and drive impactful results.</p>           
        <div className="footer-socials">
            <a href="https://linkedin.com/agiphi" target="_blank" rel="noreferrer noopener"> <FaLinkedin/></a>
            <a href="https://facebook.com/agiphi" target="_blank" rel="noreferrer noopener"> <FaFacebookF/></a>
            <a href="https://x.com/agiphi" target="_blank" rel="noreferrer noopener"> <AiOutlineX/></a>
        </div>
        </article>
        <article>
            <h4>Permalinks</h4>
            <Link to="/Clients">What Others Think</Link>
            <Link to="/Platform">Request Demo</Link>
            <Link to="/Services">Services</Link>
            <Link to="/Contact">Contact</Link>
        </article>
        <article>
            <h4>Permalinks</h4>
            <Link to="/Services">Tools and Equipment</Link>
            <Link to="/Blog">Blog</Link>
            <Link to="/Clients">Our Clients</Link>
            <Link to="/Contact">Contact Us</Link>
        </article>
        <article>
            <h4>Ecosystem</h4>
            <Link to="/Blog">Latest Update</Link>
            <Link to="/Careers">Careers</Link>
            <Link to="/Platform">Our Platform</Link>
            <Link to="/Contact">Subscribe</Link>
        </article>
    </div>
    <div className="footer__copyright">
        <p>&copy; 2024 Agiphi . All rights reserved.</p>
    </div>
    </footer>
  )
}

export default Footer
