import Header from '../../components/Header';
import HeaderImage from '../../images/header-bg.jpg';
import Screenshot1 from '../../images/IMG_1300 2.PNG'; // Importing the first screenshot
import Screenshot2 from '../../images/IMG_1301 2.PNG'; // Importing the second screenshot
import { Link } from 'react-router-dom';
import './platform.css';

const Platform = () => {
  return (
    <>
      <Header title="Agilabs Annotation Platform" image={HeaderImage}>
        Enhance your data projects with Agilabs, Agiphi’s state-of-the-art multimodal annotation platform.
      </Header>
      <div className="container platform">
        <section className="platform-development">
          <div className="text">
            <h2>Agilabs: Innovative Multimodal Annotation Platform</h2>
            <p>
              Manage and track data labeling processes in real time with Agilabs. Our platform supports seamless annotations across text, audio, and video, allowing for efficient and accurate data handling.
            </p>
          </div>
          <ul className="key-features">
            <li>
              <strong>Comprehensive Integration:</strong> Connect to both local and cloud storage solutions for secure and flexible data management.
            </li>
            <li>
              <strong>Cross-Device Compatibility:</strong> Access Agilabs from mobile, tablet, or desktop devices, ensuring flexibility for on-the-go project management.
            </li>
            <li>
              <strong>Advanced Annotation Tools:</strong> Benefit from powerful tools optimized for detailed text, audio, and video annotation workflows.
            </li>
          </ul>
        </section>
        
        <section className="visuals-demo">
          <h2>Screenshots</h2>
          <div className="screenshots">
            <img src={Screenshot1} alt="Screenshot of Agilabs Annotation Platform" />
            <img src={Screenshot2} alt="Another screenshot of Agilabs Annotation Platform" />
            {/* Add more screenshots as needed */}
          </div>
          <Link to="/Contact" className="btn">Request Demo</Link>
        </section>
      </div>
    </>
  );
};

export default Platform;
